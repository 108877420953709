<template>
  <modal name="new-member-modal" @closed="closeNewMember" :scrollable="true" :width="1200" :height="670"
         :maxHeight="600">
    <h4>{{ $t('labels.new-member') }}</h4>

    <div class="helptext">
      <p><i class="mr-2 fas fa-info-circle"></i>{{ $t('labels.new-member-info-text') }}</p>
    </div>
    <div class="mt-2">
      <reactive-base
          app="ticket_local_office"
          :credentials="user.user.es_username + ':' + user.user.es_password"
          :url="elasticUrl"
      >
        <div class="widget widget-modal">
          <div class="widget-header">
            <div class="widget-header-toolbar">
              <div class="actions">
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.police-region')"
                      componentId="policeFilter"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :showSearch="true"
                      sortBy="asc"
                      nestedField="police_region"
                      :react="{ and: ['search', 'postalCode', 'countyFilter', 'municipalityFilter', 'officeFilter', 'DataControllerSensor'] }"
                      :filterLabel="this.$t('labels.police-region')"
                      dataField="police_region.label.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.county')"
                      componentId="countyFilter"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :size="100"
                      :showSearch="true"
                      sortBy="asc"
                      nestedField="county"
                      :react="{ and: ['search', 'postalCode', 'policeFilter', 'municipalityFilter', 'officeFilter', 'DataControllerSensor'] }"
                      :filterLabel="this.$t('labels.county')"
                      dataField="county.label.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.municipality')"
                      componentId="municipalityFilter"
                      :URLParams="true"
                      fuzziness="AUTO"
                      sortBy="asc"
                      :showSearch="true"
                      :size="1000"
                      nestedField="municipality"
                      :react="{ and: ['search', 'postalCode', 'policeFilter', 'countyFilter', 'officeFilter', 'DataControllerSensor'] }"
                      :filterLabel="this.$t('labels.county')"
                      dataField="municipality.label.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.victim-support-centre')"
                      componentId="officeFilter"
                      :URLParams="true"
                      :size="100"
                      sortBy="asc"
                      fuzziness="AUTO"
                      :showSearch="true"
                      :react="{ and: ['search', 'postalCode', 'policeFilter', 'countyFilter', 'municipalityFilter', 'DataControllerSensor'] }"
                      :filterLabel="this.$t('labels.victim-support-centres')"
                      dataField="office.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="search-action ml-3">
                  <data-search
                      componentId="postalCode"
                      dataField="postal_codes.postal_code.raw"
                      nestedField="postal_codes"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      :fuzziness="0"
                      :debounce="100"
                      :placeholder="this.$t('labels.postal-code')"
                      :filterLabel="this.$t('labels.search')"
                      :autosuggest="false"
                  />
                </div>
                <div class="search-action ml-3">
                  <data-search
                      componentId="search"
                      :dataField="[
											'label',
											'label_raw',
											'other_places_indexed',
											'post_towns_indexed',
											'exposed_areas_indexed',
											'office',
											'municipality_indexed',
											'police_region_indexed',
											'county_list',
											'DataControllerSensor'
										]"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :highlight="true"
                      :URLParams="true"
                      :fuzziness="2"
                      :placeholder="this.$t('labels.postal-code')"
                      :debounce="100"
                      :filterLabel="this.$t('labels.search')"
                      :autosuggest="false"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="widget-body no-padding">
            <selected-filters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')"/>
          </div>
          <div class="widget-body no-padding">
            <ReactiveComponent componentId="DataControllerSensor">
              <div slot-scope="{ setQuery }">
                <office-filter :setQuery="setQuery" :query="defaultQuery"/>
              </div>
            </ReactiveComponent>
            <reactive-list
                :showResultStats="false"
                componentId="SearchResult"
                className="result-list-container"
                :pagination="true"
                :dataField="getSortBy"
                :sortBy="getSortByOrder ? 'desc' : 'asc'"
                :URLParams="true"
                :from="0"
                :size="15"
                :react="{ and: ['search', 'postalCode', 'policeFilter', 'countyFilter', 'municipalityFilter', 'officeFilter', 'DataControllerSensor'] }"
            >
              <table class="w-100 clickable-vuetable vuetable" slot="render" slot-scope="{ loading, error, data }">
                <thead>
                <tr>
                  <th
                      width="15%"
                      :class="{ 'sort-up': isSortField('office.raw', true), 'sort-down': isSortField('office.raw', false) }"
                      @click="changeSortBy('office.raw')"
                  >
                    {{ $t('labels.victim-support-centre') }}
                  </th>
                  <th
                      width="15%"
                      :class="{ 'sort-up': isSortField('label.raw', true), 'sort-down': isSortField('label.raw', false) }"
                      @click="changeSortBy('label.raw')"
                  >
                    {{ $t('labels.local-office') }}
                  </th>
                  <th
                      width="25%"
                      :class="{
												'sort-up': isSortField('municipality_list.raw', true),
												'sort-down': isSortField('municipality_list.raw', false),
											}"
                      @click="changeSortBy('municipality_list.raw')"
                  >
                    {{ $t('labels.municipality') }}
                  </th>
                  <th
                      width="43%"
                      :class="{
												'sort-up': isSortField('police_region_list.raw', true),
												'sort-down': isSortField('police_region_list.raw', false),
											}"
                      @click="changeSortBy('police_region_list.raw')"
                  >
                    {{ $t('labels.place') }}
                  </th>
                  <th width="10%"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in data" :key="item.id">
                  <td width="15%" v-html="item.office"></td>
                  <td width="15%" v-html="item.label"></td>
                  <td width="25%" v-html="item.municipality_indexed.replace(/,/g, ', ')"></td>
                  <td width="43%" style="font-size: 11px;" v-html="item.post_towns_indexed.replace(/,/g, ', ')"></td>
                  <td width="10%" class="text-right pr-4">
                    <button class="btn btn-success" @click="createNewMember(item.id)">{{ $t('labels.select') }}</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </reactive-list>
          </div>
        </div>
      </reactive-base>
    </div>
  </modal>
</template>
<script>
import OfficeFilter from "@/components/offices/OfficeFilter";
import BojAPI from '@/api/boj';

export default {
  components: {
    OfficeFilter
  },
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      getSortByOrder: true,
      getSortBy: '_score',
      getQueryChange: ''
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    defaultQuery() {
      if (this.$store.getters.permissions.includes('members-access-office')) {
        return {
          query: {
            bool: {
              must: [
                {
                  match: {
                    'office.raw': this.user.user.member.local_office.office.label,
                  },
                },
              ],
            },
          },
        };
      } else if (this.$store.getters.permissions.includes('members-access-localoffice')) {
        return {
          query: {
            bool: {
              must: [
                {
                  match: {
                    'label.raw': this.user.user.member.local_office.label,
                  },
                },
              ],
            },
          },
        };
      } else {
        return {
          query: {
            bool: {
              'must_not': [
                {
                  match: {
                    'office.raw': 'Placeholder',
                  },
                },
              ],
            },
          },
        };
      }
    },
  },
  mounted() {
    this.$modal.show('new-member-modal');
  },

  methods: {
    closeNewMember() {
      this.$emit('closed');
    },
    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },

    createNewMember(local_office_id) {
      BojAPI.createNewMember(local_office_id).then((response) => {
        this.$modal.hide('new-member-modal');
        this.$router.push({name: 'member.overview', params: {id: response.data}});
      });
    },
  },
};
</script>
