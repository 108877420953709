<template>
  <v-guard :permissions="['change-language']">           
    <ul class="language-selector-inline">
      <li v-for="lang in supportedLanguages"
          :key="lang"
          :selected="isCurrentLanguage(lang)"
          :class="{ 'is-selected': isCurrentLanguage(lang) }"
          :value="lang">

        <div rel="alternate" @click="changeLanguage(lang)">{{ lang.toUpperCase() }}</div>
      </li>
    </ul>
  </v-guard>
</template>
<script>
import {Trans} from '@/plugins/Translation'

export default {
  computed: {
    supportedLanguages() {
      return Trans.supportedLanguages
    },
    currentLanguage() {
      return Trans.currentLanguage
    }
  },
  methods: {
    changeLanguage(e) {
      const lang = e;
      const to = this.$router.resolve({params: {lang}})
      return Trans.changeLanguage(lang).then(() => {

        this.$store.dispatch("setNewLanguage", {lang});
        if (this.$route.path != to.location.path) {
          this.$router.push(to.location)
        }
      })
    },
    isCurrentLanguage(lang) {
      return lang === this.currentLanguage
    }
  }
}
</script>

<style>
.LanguageSwitcher {
  margin-bottom: 1rem;
}
</style>
