<script>
export default {
  name: 'office-filter',
  props: {
    setQuery: Function,
    query: Object,
  },
  mounted() {
    this.setQuery(this.query);
  },
  render: (h) => null,
};
</script>
