<template>
  <div>
    <new-member v-if="newMemberModal" @closed="closeNewMember"></new-member>
    <div class="topbar"
         :class="{ email: isSection('email'), tickets: isSection('tickets'), cases: isSection('cases'), calendar: isSection('calendar'), members: isSection('members'), offices: isSection('offices') }">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="logo"><img src="/assets/logo/logotyp_boj_brottsofferjouren_tel-1.png" height="45"/></div>
          </div>
          <div class="col-auto">
            <div class="header-actions">
              <v-guard :permissions="['section-access-tickets']">
                <router-link to="/" :class="{ active: isSection('tickets') }">{{ $t('header.tickets') }}</router-link>
              </v-guard>
              <v-guard :permissions="['section-access-court']">
                <b-dropdown :class="{ active: isSection('cases') }" :text="$t('header.cases')">
                    <b-dropdown-item-button @click="goToWitness">{{ $t('labels.go-to-mobile-version') }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="$router.push('/cases')">{{ $t('labels.go-to-desktop-version') }}
                    </b-dropdown-item-button>
                </b-dropdown>
              </v-guard>
              <v-guard :permissions="['section-access-calendar']">
                <router-link to="/calendar" :class="{ active: isSection('calendar') }">{{ $t('header.calendar') }}</router-link>
              </v-guard>
              <v-guard :permissions="['section-access-offices']">
                <router-link to="/offices" :class="{ active: isSection('offices') }">{{ $t('header.offices') }}</router-link>
              </v-guard>
              <v-guard :permissions="['section-access-members']">
                <router-link to="/members" :class="{ active: isSection('members') }">{{ $t('header.members') }}</router-link>
              </v-guard>
              <v-guard :permissions="['section-access-mail']">
                <router-link to="/email" :class="{ active: isSection('email') }">{{ $t('header.email') }}</router-link>
              </v-guard>
            </div>
          </div>
          <div class="col-auto text-right username">
            <TheLanguageSwitcher v-if="1 == 1"/>
            <div>{{ user.user.name }}<br/>
              <router-link to="/logout">{{ $t('header.logout') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header"
         :class="{ email: isSection('email'), tickets: isSection('tickets'), members: isSection('members'), offices: isSection('offices'), cases: isSection('cases') }">
      <div class="container-fluid header-bottom">
        <div class="container">
          <div class="row h-100">
            <div class="col-xl-9 h-100">
              <div class="menu-wrapper flex-wrap">
                <div class="primary-nav" id="nav-tickets" v-if="isSection('tickets')">
                  <v-guard :permissions="['ticket-page-active']">
                    <router-link to="/">{{ $t('labels.actively') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['tickets-page-closed']">
                    <router-link to="/tickets/completed">{{ $t('labels.completed-tickets') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['tickets-page-all']">
                    <router-link to="/tickets/all">{{ $t('labels.all-tickets') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['tickets-page-mytickets']">
                    <router-link to="/tickets/my-tickets">{{ $t('labels.my-tickets') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['tickets-page-language']">
                    <router-link to="/tickets/language">{{ $t('labels.language-tickets') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['tickets-page-unconfirmed']">
                    <router-link to="/tickets/unverified">{{ $t('labels.unverified') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['tickets-page-unassigned']">
                    <router-link to="/tickets/unassigned">{{ $t('labels.unassigned') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['tickets-page-users']">
                    <router-link to="/tickets/users">{{ $t('labels.users-tickets') }}</router-link>
                  </v-guard>
                   <v-guard :permissions="['tickets-page-quality']">
                    <router-link to="/tickets/quality">{{ $t('labels.interview') }} A</router-link>
                  </v-guard>
                  <v-guard :permissions="['section-access-police-import']">
                    <router-link :to="{ name: 'police-import'}">{{ $t('labels.import') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['tickets-page-statistics']">
                    <router-link to="/tickets/statistics">{{ $t('labels.statistics') }} Gammal</router-link>
                  </v-guard>
                  <v-guard :permissions="['tickets-page-statistics-new']">
                    <router-link to="/tickets/statistics-new">{{ $t('labels.statistics') }}</router-link>
                  </v-guard>
                </div>
                <div class="primary-nav" id="nav-witness" v-if="isSection('cases')">
                  <v-guard :permissions="['court-page-active']">
                    <router-link to="/cases">{{ $t('labels.overview') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['court-page-time']">
                    <router-link to="/cases/times">Nedlagd tid</router-link>
                  </v-guard>
                  <v-guard :permissions="['court-page-user']">
                    <router-link to="/cases/users">Handläggare</router-link>
                  </v-guard>
                  <v-guard :permissions="['testimony-interview-d-view']">
                      <router-link :to="{ name: 'cases.interview-d'}">Intervju D</router-link>
                    </v-guard>
                  <v-guard :permissions="['court-page-statistics']">
                    <router-link to="/cases/statistics">Statistik D</router-link>
                  </v-guard>
                </div>
                <div class="primary-nav" id="nav-members" v-if="isSection('members')">
                  <v-guard :permissions="['members-page-overview']">
                    <router-link to="/members">{{ $t('labels.overview') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['members-page-board']">
                    <router-link to="/members/board">{{ $t('labels.trusted-members') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['members-page-coordinators']">
                    <router-link to="/members/coordinators">{{ $t('labels.coordinator') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['members-page-volunteers']">
                    <router-link to="/members/support">{{ $t('labels.supporters') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['members-files-view']">
                    <router-link to="/members/downloads">{{ $t('labels.files') }}</router-link>
                  </v-guard>
                </div>

                <div class="primary-nav" id="nav-email" v-if="isSection('email')">
                  <router-link to="/email">{{ $t('labels.overview') }}</router-link>
                  <!--<router-link to="/email/drafts">Utkast</router-link>
                  <router-link to="/email/drafts">Planerat</router-link>
                  <router-link to="/email/drafts">Skickat</router-link>-->
                  <router-link to="/email/lists">{{ $t('labels.list') }}</router-link>
                  <router-link to="/email/signatures">{{ $t('labels.signatures') }}</router-link>
                </div>

                <div class="primary-nav" id="nav-offices" v-if="isSection('offices')">
                  <v-guard :permissions="['offices-page-overview']">
                    <router-link to="/offices">{{ $t('labels.overview') }}</router-link>
                  </v-guard>
                  <v-guard :permissions="['offices-page-statistics']">
                    <router-link to="/offices/statistics">{{ $t('labels.statistics-combo') }}</router-link>
                  </v-guard>
                </div>
              </div>
            </div>

            <div class="col-xl-3">
              <div class="header-toolbar flex-row-reverse" v-if="isSection('tickets')">
                <v-guard :permissions="['tickets-new-ticket']">
                  <router-link to="/tickets/new-ticket" class="btn btn-wide font-bold btn-primary">{{
                      $t('labels.new-ticket')
                    }}
                  </router-link>
                </v-guard>
              </div>
              <div class="header-toolbar flex-row-reverse" v-if="isSectionExactMatch('cases') || isSectionExactMatch('case')">
                <v-guard :permissions="['cases-new-case']">
                  <router-link to="/cases/new-case" class="btn btn-wide font-bold btn-primary">Nytt mål</router-link>
                </v-guard>
              </div>
              <div class="header-toolbar flex-row-reverse" v-if="isSectionExactMatch('times') || isSectionExactMatch('time')">
                <v-guard :permissions="['cases-new-time']">
                  <router-link to="/cases/new-time" class="btn btn-wide font-bold btn-primary">Registrera tid</router-link>
                </v-guard>
              </div>
              <div class="header-toolbar flex-row-reverse" v-if="isSection('members')">
                <v-guard :permissions="['member-new-member']">
                  <button @click="newMember" class="btn btn-wide font-bold btn-primary">{{ $t('labels.new-member') }}</button>
                </v-guard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container content">
      <slot/>
    </div>

    <notifications group="foo"/>
  </div>
</template>

<style lang="scss"></style>
<script>
import TheLanguageSwitcher from '@/components/Language';
import NewMember from '@/components/member/NewMember';

export default {
  name: 'survey',

  data() {
    return {
      newMemberModal: false,
      witnessUrl: process.env.VUE_APP_WITNESS_LINK,
    };
  },
  methods: {
    closeNewMember() {
      this.newMemberModal = false;
    },
    newMember() {
      this.newMemberModal = true;
    },

    logout() {
    },

    isSection(section) {
      let currentPath = this.$router.currentRoute.path.split('/');

      if (currentPath[1] === section) {
        return true;
      }

      return false;
    },
    isSectionExactMatch(section) {
      const newMatch = this.$router.currentRoute.path.match(/^[^\d]*/g).toString().split('/');

      const lastElement = newMatch[newMatch.length - 1] == '' ? newMatch[newMatch.length - 2] : newMatch[newMatch.length - 1];

      if (section == lastElement) {
        return true;
      }

      return false;
    },
    goToWitness() {
      window.location.href = this.witnessUrl + '/main-menu';
    }
  },

  components: {
    NewMember,
    TheLanguageSwitcher,
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>
